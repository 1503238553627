import * as React from 'react'
import {Layout} from "../components/layout"
import { ServiceCard } from "../components/service-card"
import servicesJson from "../../content/services.json"

export default function ServicesPage() {
    const {title, content} = servicesJson;

    return (
        <Layout>
            <div className="container">
            <h1 className="title">{title}</h1>
            {content.map(({btnLink, btnName, title, description, showAccreditation}, index)=>{
                return (
                  <ServiceCard key={index + title} btnLink={btnLink} btnName={btnName} title={title} description={description} showAccreditation={showAccreditation} isDark={false} />
                )
              })}
            </div>
        </Layout>
    )
}