import * as React from 'react'
import { Link } from "gatsby"
import slugify from "@sindresorhus/slugify"

export function ServiceCard({btnLink, btnName, title, description, showAccreditation, isDark }) {
    let background = isDark ? "dark" : "light";
    let btnBack = isDark ? "white-background" : "blue-background";
    let hrBack = isDark ? "text-color-inverted" : "text-color-dark";
    let accreditationHandle = showAccreditation ? 1 : 0;
    return (
        <div className={["family-study-card", background].join(" ")}>
            {showAccreditation && <div className="accredited-label">AABB Accredited</div>}
            <h3 className="subTitle">{title}</h3>
            <hr className={hrBack} />
            <p>{description}</p>
            <Link to={btnLink + "?s=" + slugify(title) + "&a=" + accreditationHandle } className={["btn-general", btnBack].join(" ")}>{btnName}</Link>
        </div>
    )
}